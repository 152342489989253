import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'
import _ from 'lodash'

export default {
  mixins: [LoadingMixin],
  components: {
    AppImage,
    AppTitle,
    Loader,
    Modal,
  },
  props: {
    shipper: Object,
  },
  data() {
    return {
      query: '',
      branches: [],
    }
  },
  mounted() {
    this.search()
    this.$refs.modal.$on('show', () => {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    })
  },
  methods: {
    debounceSearch: _.debounce(function() {
      this.search()
    }, 200),
    async search() {
      this.startLoading()
      const result = await this.$store.dispatch('cart/GET_BRANCHES', {
        query: this.query,
        shipperId: this.shipper.id,
        perPage: 50,
      })
      this.branches = result.data
      this.stopLoading()
    },
    select(branch) {
      this.$store.commit('cart/SET_BRANCH', branch)
      this.$refs.modal.hide()
    },
    open() {
      this.$refs.modal.show()
    },
  },
}
